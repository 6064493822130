import React from 'react'
import config from 'config';

// Partials
import Btn from 'views/partials/btn'
import Image from 'views/partials/image'

// Deps
import getCdnImage from 'functions/get-cdn-image'
import formatNumber from 'functions/format-number'
import isEqual from 'lodash/isEqual'
import clone from 'lodash/clone'

export default class Sale extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			filteredProducts: [],
			activeFilter: false,
			isComplete: false,
		}

		this.filterProducts = this.filterProducts.bind(this);
		this.setFilter = this.setFilter.bind(this);
	}

	componentDidMount() {
		if(this.props.sale){
			this.filterProducts();
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if(this.props.sale !== false){
			if(prevState.activeFilter !== this.state.activeFilter){
				this.filterProducts();
			}
			else if(prevProps.sale === false || !isEqual(prevProps.sale, this.props.sale)) {
				this.filterProducts();
			}
		}
	}

	filterProducts(){
		let vm = this;
		let sale = clone(vm.props.sale);
		let collected = true;

		if(sale !== false && sale.product_groups.length){
			let filteredProducts = sale.product_groups.reduce((pg, g) => {
				let group = clone(g);
				let filteredProds = group.products.filter((prod) => {
					if(prod.product_erp_code === config.deliveryProductErpCode) {
						return false;
					}
					let ret = false;

					if((prod.quantity.total - prod.quantity.collected - prod.quantity.unavailable) > 0){
						collected = false;
					}

					switch(vm.state.activeFilter) {
						case "collected":
							ret = prod.quantity.collected > 0;
						break;
						case "unavailable":
							ret = prod.quantity.unavailable > 0;
						break;
						case "packed":
							ret = prod.quantity.packed > 0;
						break;
						default:
							ret = true;
						break;
					}

					return ret;
				});
				if(filteredProds.length){
					group.products = filteredProds;
					pg.push(group);
				}
				return pg;
			}, []);

			this.setState({ filteredProducts: filteredProducts, isComplete: collected })
		}
	}

	setFilter(e){
		if(e.target.checked){
			this.setState({ activeFilter: e.target.value })
		}
		else {
			this.setState({ activeFilter: false })
		}
	}

	render(){
		let filteredProducts = this.state.filteredProducts;

		return (
			<React.Fragment>
				<div className="section sale-filters">
					<div className="filters-wrap wrapper">
						<div className="inputwrap type-checkbox">
							<div className="checkwrap">
								<input
									type="checkbox"
									id="filters-collected"
									value="collected"
									checked={this.state.activeFilter === 'collected'}
									onChange={this.setFilter} />
									<label htmlFor="filters-collected">
										<i></i> Toplanan Ürünler
									</label>
							</div>
						</div>

						<div className="inputwrap type-checkbox">
							<div className="checkwrap">
								<input
									type="checkbox"
									id="filters-unavailable"
									value="unavailable"
									checked={this.state.activeFilter === 'unavailable'}
									onChange={this.setFilter} />
									<label htmlFor="filters-unavailable">
										<i></i> Bulunamayan Ürünler
									</label>
							</div>
						</div>

						{
							// <div className="inputwrap type-checkbox">
							// 	<div className="checkwrap">
							// 		<input
							// 			type="checkbox"
							// 			id="filters-packed"
							// 			value="packed"
							// 			checked={this.state.activeFilter === 'packed'}
							// 			onChange={this.setFilter} />
							// 			<label htmlFor="filters-packed">
							// 				<i></i> Paketlenen Ürünler
							// 			</label>
							// 	</div>
							// </div>
						}
					</div>
				</div>
				{/* <div className="section sale-topcontrols wrapper">
					<Btn className="block" onClick={() => {window.print()}}>Listeyi Yazdır</Btn>
				</div> */}
				<ul className="section sale-products">
				{filteredProducts.map((group, nth) => (
					<li className="products-group" key={nth}>
						<div className="group-head">
							<div className="wrapper">
								{group.title}
							</div>
						</div>
						<div className="group-products">
							<div className="wrapper">
							{group.products.map((product, pnth) => (
								<div className="products-product" key={nth+'_'+pnth}>
									<Image
										bg
										contain
										src={getCdnImage(product.image, { width: 100, height: 100 })}
										className="product-image" />

									<div className="product-text">
										<div className="text-maincontent">
											<strong className="product-title">{product.product_name}</strong>
											<div className="product-barcode">
												<strong>Ürün Kodu: </strong>
												<span>{product.product_erp_code ? product.product_erp_code : '-'}</span>
											</div>
										</div>
										<div className="product-bottominfo">
											<div className="product-price">{formatNumber(product.product_price, { decimals: 2 })} TL</div>
											<div className="product-quantity">
												<p className="quantity-num total">{product.quantity.total} {product.quantity_type_text}</p>
												{product.quantity.packed > 0 &&
													<p className="quantity-num sub packed">Paketlenen: {product.quantity.packed}</p>
												}
												{product.quantity.collected > 0 &&
													<p className="quantity-num sub collected">Toplanan: {product.quantity.collected}</p>
												}
												{product.quantity.unavailable > 0 &&
													<p className="quantity-num sub unavailable">Bulunamayan: {product.quantity.unavailable}</p>
												}
											</div>
										</div>
									</div>
								</div>
							))}
							</div>
						</div>
					</li>
				))}
				</ul>

				<div className="section sale-controls">					
					{this.props.sale.collectable ?
						<Btn
							disabled={this.props.loading}
							className={'controls-btn wide'}
							onClick={() => {this.props.onNavigate('collection')}}>
							Toplamaya Başla
						</Btn>
						:
						<Btn
							tag="div"
							disabled
							className="controls-btn wide error">
							<>Bu sipariş ileri tarihlidir, toplama işlemi işlemi yapılamaz.</>
						</Btn>
					}
				</div>
			</React.Fragment>
		);
	}
}