// Deps
import React from 'react';
import saleServices from 'services/sale'
import { redirect } from 'controllers/navigator'
import { openModal } from 'utils/modals'
import formatDate from 'functions/format-date'

// Partials
import Btn from 'views/partials/btn'
import Loader from 'views/partials/loader'

// Sections
import BarcodeInput from 'views/sections/barcode-input'

export default class Collection extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			loading: true,
			sales: false,
			page: 1,
			pages: 1,
		}

		this.barcodeEntered = this.barcodeEntered.bind(this);
		this.getSalesList = this.getSalesList.bind(this);
	}

	componentDidMount() {
		this.getSalesList();
	}

	componentDidUpdate(prevProps, prevState) {
		if(this.state.page !== prevState.page) {
			this.getSalesList();
		}
	}

	getSalesList() {
		this.setState({
			loading: true,
		});

		window.scrollTo(0, 0);

		saleServices.getSales({ status: 1, pagination: true, page: this.state.page }).then((payload) => {
			const today = new Date();
			const todayDate = new Date(today.getFullYear(), today.getMonth(), today.getDate());

			this.setState({
				sales: payload.sales.map((sale, nth) => {
					const dateVals = sale.delivery_calendar_date.split('-').map(n => parseInt(n));
					const saleDate = new Date(dateVals[0], dateVals[1] -1, dateVals[2]);
					
					return {
						...sale,
						collectable: todayDate >= saleDate,
					}
				}),
				page: parseInt(payload.pagination.page),
				pages: parseInt(payload.pagination.page_count),
			});
		}).catch((e) => {
			console.error('Listeleme hatası:', e);
			this.setState({
				sales: [],
				page: 1,
				pages: 1
			});
		}).finally(() => {
			this.setState({
				loading: false
			})
		})
	}

	barcodeEntered(barcode) {
		let selectedSale = this.state.sales.filter(sale => (sale.sale_code === barcode || sale.id === parseInt(barcode)));
		if(selectedSale.length){
			redirect('collectionDetail', {id: selectedSale[0].id});
		}
		else {
			this.setState({ loading: true });

			saleServices.getSales({ status: 2, query: barcode }).then((payload) => {
				if(payload.sales.length === 1) {
					redirect('collectionDetail', {id: payload.sales[0].id});
				}
				else {
					openModal('text', { content: 'Sipariş bulunamadı.' });
				}
			}).finally(() => {
				this.setState({
					loading: false
				});
			}).catch(() => {
				openModal('text', { content: 'Sipariş bulunamadı.' });
			});
			
		}
	}

	render(){
		return (
			<div className="section depot-sales loader-container">
				<Loader loading={this.state.loading} />
				{this.state.sales  &&
					<React.Fragment>
						<BarcodeInput onSubmit={this.barcodeEntered} />
						<div className="wrapper">
							{this.state.sales.length > 0 ?
								<ul className="sales-list listing">
									{this.state.sales.map((sale, nth) => (
										<li className="listing-item" key={nth}>
											<div className="item-field">
												<strong>Satış Kodu</strong>
												<span>{sale.id} - {sale.sale_code}</span>
											</div>
											<div className="item-field">
												<strong>Müşteri Adı</strong>
												<span>{sale.sale_client}</span>
											</div>
											<div className="item-field">
												<strong>Durum</strong>
												<span>{sale.status_text}</span>
											</div>
											<div className="item-field">
												<strong>Ödeme Yöntemi</strong>
												<span>{sale.sale_payment_name}</span>
											</div>
											<div className="item-field">
												<strong>Tutar</strong>
												<span>{sale.total} TL</span>
											</div>
											<div className="item-field">
												<strong>Teslimat Saati</strong>
												<span className="date">
													<span>{formatDate(sale.delivery_calendar_date, 'DD MMMM YYYY')} - {sale.delivery_calendar_hour}</span>
												</span>
											</div>
											<div className="item-controls">
												{sale.collectable ?
													<Btn
														className={sale.fix_status === 2 ? 'success' : undefined}
														low
														onClick={() => { this.barcodeEntered(sale.sale_code); }}>
														Toplamaya Başla
													</Btn>
													:
													<Btn
														className="warning"
														low
														onClick={() => { this.barcodeEntered(sale.sale_code); }}>
														Siparişi Gör
													</Btn>
												}
											</div>
										</li>
									))}
								</ul>
							:
								<React.Fragment>
									{this.state.sales !== null &&
										<div className="listing-message">
											Toplanacak sipariş yok.
										</div>
									}
								</React.Fragment>
							}	

							{this.state.pages > 1 &&
								<div className="listing-pagination">
									<button
										className="pagination-nav"
										disabled={this.state.page <= 1}
										onClick={() => { this.setState({ page: (this.state.page - 1)}) }}>
										<i className="icon-angle-left"></i>
									</button>
									<div className="pagination-at">Sayfa: <strong>{this.state.page}/{this.state.pages}</strong></div>
									<button
										className="pagination-nav"
										disabled={this.state.page >= this.state.pages}
										onClick={() => { this.setState({ page: (this.state.page + 1)}) }}>
										<i className="icon-angle-right"></i>
									</button>
								</div>
							}
						</div>
					</React.Fragment>
				}
			</div>
		);
	}
}