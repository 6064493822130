// Deps
import { useState, useRef, useEffect } from 'react'
import debounce from 'lodash/debounce'

const useDebounce = (inputVal, timeout = 60, initVal) => {
	const initialValue = useRef(initVal);
	const _mounted = useRef(false);

	const [val, setVal] = useState(initialValue.current !== undefined ? initialValue.current : inputVal);

	const debounceFunct = useRef(debounce((newVal) => {
		if(_mounted.current) {
			setVal(newVal);
		}
	}, timeout, { leading: false }))

	useEffect(() => {
		_mounted.current = true;

		return () => {
			_mounted.current = false;
		}
	}, [])

	useEffect(() => {
		debounceFunct.current(inputVal);
	}, [inputVal])

	return val;
}

export default useDebounce;